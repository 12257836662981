import React from "react";

import styles from "./Cookies.module.scss";

export default function Cookies() {
  return (
    <div className={styles["container"]}>
      <div className={styles["body"]}>
        <h1>Cookies</h1>
        <h2>What are cookies?</h2>
        <p>
          Cookies are small text files which are downloaded to your computer,
          tablet or mobile phone when you visit a website or application. The
          website or application may retrieve these cookies from your web
          browser (eg Internet Explorer, Mozilla Firefox or Google Chrome) each
          time you visit, so they can recognise you, remember your preferences
          and provide you with a more secure online experience.
        </p>
        <p>
          Generally, cookies are very useful and are a common method used by
          almost every website you visit because they help to make your online
          experience as smooth as possible. For security reasons, many websites
          will not function at all without the use of cookies (or other similar
          technologies, such as &quot;web beacons&quot; or &quot;tags&quot;).
        </p>
        <p>
          Cookies generally do not hold any information to identify an
          individual person, but are instead used to identify a browser on an
          individual machine.
        </p>
        <p>
          If you prefer, you can restrict, block or delete cookies by changing
          your browser settings but that may mean that the website won&apos;t
          work properly
        </p>
        <div />
        <h2>Types of cookies</h2>
        <h3>Necessary cookies</h3>
        <p>
          These cookies are essential in helping you to make use of the features
          and services we offer on the Jawg website. Without these cookies, the
          services you want to use cannot be provided. These cookies do not
          gather information about you that could be used to identify you, and
          they do not monitor or remember where you have been on the internet.
        </p>
        <h3>Functional cookies</h3>
        <p>
          These cookies allow us to provide you with a better online experience
          when you use our website. They do not gather or store any information
          which would allow us to identify you personally.
        </p>
        <h3>Performance cookies</h3>
        <p>
          Performance cookies help us to understand how our customers use our
          site, so we can keep our products and services relevant, easy to use
          and up to date. For example, we can see which products and services
          are most popular, identify when and where errors occur, and test
          different versions of a page in order to provide an improved online
          experience.
        </p>
        <p>
          Sometimes, the services we use to collect this information may be
          operated by other companies on our behalf. They may use similar
          technologies to cookies, known as &quot;web beacons&quot; or
          &quot;tags&quot;. These are anonymous and, as they are only used for
          statistical purposes, they do not contain or collect any information
          that identifies you.
        </p>
        <div />
        <h2>Managing cookies</h2>
        <p>
          Most internet browsers allow you to erase cookies from your computer
          hard drive, block all cookies (or just third-party cookies) or warn
          you before a cookie is stored on your device.
        </p>
        <p>
          {" "}
          Please note, if you choose to block all cookies, our site will not
          function as intended and you will not be able to use or access many of
          the services we provide. If you have blocked all cookies and wish to
          make full use of the features and services we offer, you will need to
          enable your cookies. You can do this in your browser (
          <em>see below</em>).{" "}
        </p>
        <p>
          Rather than blocking all cookies, you can choose to only block
          third-party cookies which will still allow our website to function as
          intended.
        </p>
        <div />
        <h3>How to manage cookies on your PC</h3>
        <p>To enable cookies on our website, follow the steps below.</p>{" "}
        <div className={styles["block"]}>
          <h4>Google Chrome</h4>
          <ol>
            <li>
              Click &quot;Tools&quot; at the top of your browser and select
              &quot;Settings&quot;.
            </li>
            <li>
              Click &quot;Show advanced settings&quot;, scroll down to the
              section &quot;Privacy&quot; and click &quot;Content
              Settings.&quot;
            </li>
            <li>
              Select &quot;Allow local data to be set&quot;. To only acept
              first-party cookies, check the box next to &quot;Block all
              third-party cookies without exception&quot;
            </li>
          </ol>
          <h4>Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0</h4>
          <ol>
            <li>
              Click &quot;Tools&quot; at the top of your browser and select
              &quot;Internet Options&quot;, then click the &quot;Privacy&quot;
              tab.
            </li>
            <li>
              Check that the level of your privacy is set to Medium or lower,
              which will allow the use of cookies in your browser.
            </li>
            <li>
              If set above medium level it will prevent the use of cookies.
            </li>
          </ol>
          <h4>Mozilla Firefox</h4>
          <ol>
            <li>
              Click &quot;Tools&quot; at the top of your browser and select
              &quot;Options&quot;.
            </li>
            <li>Then select the &quot;Privacy&quot; icon.</li>
            <li>
              Click the &quot;Cookies&quot; and select &quot;Allow pages to
              create a cookie.&quot;
            </li>
          </ol>
          <h4>Safari</h4>
          <ol>
            <li>
              Click the gear icon at the top of your browser and select
              &quot;Settings&quot;.
            </li>
            <li>
              Click the &quot;Privacy&quot; tab, then select the option
              &quot;Disable the use of cookies by third parties and advertising
              cookies.&quot;
            </li>
            <li>Click &quot;Save&quot;.</li>
          </ol>
          <div />
        </div>
        <h3>How to manage cookies on your Mac</h3>
        <p>To enable cookies on our website, follow the steps below.</p>{" "}
        <div className={styles["block"]}>
          <h4>Microsoft Internet Explorer 5.0 on OSX</h4>
          <ol>
            <li>
              Click on &quot;Explorer&quot; at the top of your browser and
              select &quot;Settings&quot;.
            </li>
            <li>
              Scroll down to the &quot;Cookies&quot; section in the
              &quot;Received Files&quot;.
            </li>
            <li>Select &quot;Do not ask.&quot;</li>
          </ol>
          <h4>Safari on OSX</h4>
          <ol>
            <li>
              Click &quot;Safari&quot; on the top of your browser and select
              &quot;Settings&quot;.
            </li>
            <li>
              Click the &quot;Privacy&quot; and then &quot;Enable cookies.&quot;
            </li>
            <li>Select &quot;only the pages you have visited.&quot;</li>
          </ol>
          <h4>Mozilla and Netscape on OSX</h4>
          <ol>
            <li>
              Click &quot;Mozilla&quot; or &quot;Netscape&quot; at the top of
              your browser and select &quot;Settings&quot;.
            </li>
            <li>
              Scroll down to the &quot;Cookies&quot; under &quot;Privacy &amp;
              Security&quot;.
            </li>
            <li>Select &quot;Allow cookies only to the original site.&quot;</li>
          </ol>
          <h4>Opera</h4>
          <ol>
            <li>
              Click &quot;Menu&quot; on the top of your browser and select
              &quot;Settings&quot;.
            </li>
            <li>
              Then select &quot;Options&quot; tab and the &quot;Advanced&quot;.
            </li>
            <li>Select &quot;Enable cookies.&quot;</li>
          </ol>
        </div>
      </div>
    </div>
  );
}
